<template>
  <div v-if="user" :style="$vuetify.breakpoint.smAndDown ? { padding: '15% 5%' } : { padding: '8% 5%' }">
    <div>
      <h1>Hello {{ firstName }}</h1>
      <v-tabs :style="{ paddingTop: '4%' }" height="100%">
        <v-tab :style="$vuetify.breakpoint.smAndDown ? { width: '250px' } : { width: '350px' }">
          <v-card @click="openOrders()" :style="{ padding: '10px', backgroundColor: colors.PRIMARY }" width="100%" height = "120px"
            outlined>
            <v-row>
              <v-col cols="4">
                <img width="100%" src="../assets/order.png" />
              </v-col>
              <v-col cols="8">
                <div :style="{ textAlign: 'left' }">Your Orders</div>
                <div :style="{ fontSize: '8px', textAlign: 'left', paddingTop: '2%', color: colors.SECONDARY }">
                  View Orders, Check Status, and More.
                </div>
              </v-col>
            </v-row>
          </v-card>
        </v-tab>
        <v-tab :style="$vuetify.breakpoint.smAndDown ? { width: '250px' } : { width: '350px' }">
          <v-card @click="openProfile()" :style="{ padding: '10px', backgroundColor: colors.PRIMARY }" width="100%" height = "120px"
            outlined>
            <v-row>
              <v-col cols="4">
                <img width="100%" src="../assets/profile.png" />
              </v-col>
              <v-col cols="8">
                <div :style="{ textAlign: 'left' }">Your Profile</div>
                <div :style="{ fontSize: '8px', textAlign: 'left', paddingTop: '2%', color: colors.SECONDARY }">
                  Add/Manage Personal information.
                </div>
              </v-col>
            </v-row>
          </v-card>
        </v-tab>
      </v-tabs>
    </div>
    <div :style="{ padding: '5% 0' }">
      <YourProfile :profile="profile" :orders="orders" />
      <div v-if="!profile && orders">
        <h3 class="not-margin">Orders</h3>
        <Orders :orders="profileOrder" :user="true" />
      </div>
    </div>
  </div>
</template>

<script>
import { COLORS } from '@/plugins/branding.js';

export default {
  name: 'Profile',
  components: { 
    YourProfile: () => import('@/components/YourProfile.vue'),
    Orders: () => import('@/components/Orders.vue'),
  },
  data: () => ({
    profile: false,
    orders: true,
    colors: COLORS,
  }),
  computed: {
    profileOrder() {
      return this.$store.state.userOrders;
    },
    profileId() {
      return this.$store.state.profileId;
    },
    user() {
      return this.$store.state.user;
    },
    firstName() {
        return this.$store.state.profileFirstName;
    },
  },
  methods: {
    openProfile() {
      this.profile = true;
      this.orders = false;
    },
    openOrders() {
      this.orders = true;
      this.profile = false;
    },
  },
  mounted() {
    this.$store.dispatch('getUserOrders');
  },
};
</script>